<template>
  <div>
    <div class="input-group">
      <input ref="input" v-model="newItem" class="form-control" v-bind="$attrs" />
      <div class="input-group-append">
        <button type="button" class="btn btn-primary" @click="add"><i class="fa fa-plus fa-fw"></i></button>
      </div>
    </div>

    <ul class="list-group">
      <li
        v-for="(item, index) in value"
        :key="index"
        class="d-flex bg-lighter py-1 px-2 justify-content-between align-items-center select-border rounded-sm font-w600 mt-2"
      >
        <div>{{ item }}</div>
        <a class="d-block font-w600 text-muted" href="#" @click.prevent="remove(index)"><i class="fa fa-fw fa-times"></i></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'MultiSelectText',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    unique: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newItem: ''
    };
  },
  methods: {
    add() {
      const newValue = [...this.value, this.newItem];
      if (this.unique && this.value.includes(this.newItem)) {
        this.$emit('error', 'Duplicate entry not allowed');
      } else if (this.unique && !this.value.includes(this.newItem)) {
        this.$emit('input', newValue);
        this.$emit('error', '');
      } else {
        this.$emit('input', newValue);
        this.$emit('error', '');
      }
      this.newItem = '';
    },
    remove(index) {
      const newValue = [...this.value];

      newValue.splice(index, 1);

      this.$emit('input', newValue);
    }
  }
};
</script>
<style lang="scss" scoped>
.select-border {
  border-left: 5px solid #65c198;
}
</style>
