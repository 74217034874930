<template>
  <div>
    <p class="font-w600 mb-2">Selected Options ({{ value.length }} items)</p>
    <div class="MultiSelectOptions mb-3">
      <div v-if="!value || value.length === 0" class="alert alert-warning mb-2">No options selected</div>
      <div v-else class="bg-lightest rounded-md py-2 px-3">
        <div class="MultiSelectOptions-dropdown">
          <div v-for="(item, index) in value" :key="index" class="d-flex justify-content-between align-items-center my-2 MultiSelectOptions-item">
            <div class="font-w600">
              {{ options.find(option => option.value === item) ? options.find(option => option.value === item).label : item }}
            </div>
            <button type="button" class="btn btn-sm text-danger" @click="removeFromList(item, index)"><i class="fa fa-fw fa-trash"></i></button>
          </div>
        </div>
      </div>
      <div class="text-right mt-1">
        <a v-if="value.length > 0" href="#" class="text-primary font-w600" @click.prevent="onClickClear"> Unselect All</a>
      </div>
    </div>

    <p class="font-w600 mb-2">Available Options ({{ visibleOptions.length }} items)</p>
    <div class="MultiSelectOptions">
      <div v-if="search" class="form-group MultiSelectOptions-form mb-2">
        <div class="input-group">
          <input class="form-control" placeholder="Search..." @input="onSearch" />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fa fa-fw fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div v-if="!visibleOptions || visibleOptions.length === 0" class="alert alert-warning mb-1">No options available</div>
      <div v-else class="MultiSelectOptions-dropdown-wrapper">
        <ul class="MultiSelectOptions-dropdown">
          <li
            v-for="(item, index) in visibleOptions"
            :key="index"
            class="MultiSelectOptions-dropdown-item d-flex justify-content-between align-items-center font-w600"
            @click.prevent="addToList(item, index)"
          >
            {{ item.label }}
          </li>
        </ul>
        <div class="mt-2 text-right">
          <a v-if="visibleOptions.length !== 0" href="#" class="text-primary font-w600 ml-2 mr-2" @click.prevent="onClickSelectAll">Select All</a>
          <a v-if="visibleOptions.length === 0" href="#" class="text-primary font-w600 ml-2 mr-2" @click.prevent="onClickClear">Unselect All</a>
          <a href="#" class="text-primary font-w600 ml-2" @click.prevent="onClickClear">Clear</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiSelectOptions',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    overflow: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    alt: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      results: {
        data: [],
        total: 0,
        limit: 10,
        skip: 0
      },
      page: 1,
      limit: 10,
      query: '',
      loading: false
    };
  },
  computed: {
    visibleOptions() {
      const filter = this.options.filter(option => !this.value.includes(option.value));
      if (this.query) return filter.filter(o => o.label.toLowerCase().includes(this.query.toLowerCase()));
      return filter;
    }
  },
  methods: {
    onSearch(e) {
      this.query = e.target.value;
    },
    addToList(item) {
      const newValue = [...this.value, item.value];
      this.$emit('input', newValue);
      this.$emit('itemAdded', item.value);
    },
    removeFromList(item) {
      const newValue = [...this.value].filter(value => value !== item);

      this.$emit('input', newValue);
      this.$emit('itemRemoved', item);
    },
    onClickSelectAll() {
      const newValue = this.options.map(o => o.value);
      this.$emit('input', newValue);
    },
    onClickClear() {
      this.$emit('input', []);
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/_scss/custom/variables';

.MultiSelectOptions {
  position: relative;
}
.MultiSelectOptions-form {
  position: relative;
}
.MultiSelectOptions-dropdown-wrapper {
  border: 2px solid $gray-lines;
  border-radius: 0.3rem;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.45rem;
}
.MultiSelectOptions-dropdown {
  padding: 0 0.375rem 0 0;
  margin: 0;
  overflow-y: scroll;
  max-height: 275px;
}
.form-control-alt .MultiSelectOptions-dropdown-wrapper {
  background: #fff;
}
.form-control-alt .MultiSelectOptions .input-group-text {
  background: $primary;
  color: #fff;
}
// .MultiSelectOptions-dropdown::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 7px;
// }
// .MultiSelectOptions-dropdown::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: $gray-lines;
//   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }
.MultiSelectOptions-dropdown-item {
  margin: 0;
  padding: 5px 8px;
  cursor: pointer;
}
.MultiSelectOptions-dropdown-item:hover {
  background: $gray-hover;
  border-radius: 5px;
}
.MultiSelectOptions {
  &-item {
    position: relative;
    margin-left: 20px;
  }
  &-item:after {
    background: $primary;
    border-radius: 6px;
    content: '';
    height: 70%;
    left: -14px;
    position: absolute;
    width: 4px;
    top: calc(15%);
    padding: 5px 0;
  }
}
</style>
