var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tag-input-container rounded-sm",
    on: {
      "click": _vm.focusInput
    }
  }, [_vm._l(_vm.value, function (tag) {
    return _c('span', {
      key: tag,
      staticClass: "pl-2 bg-light rounded-sm ml-2"
    }, [_vm._v(" " + _vm._s(tag) + " "), _c('button', {
      staticClass: "bg-light text-muted rounded-sm border-0",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.removeTag(tag);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times"
    })])]);
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newTag,
      expression: "newTag"
    }],
    ref: "tagInput",
    staticClass: "form-control pl-2",
    class: {
      'ml-1': _vm.value.length > 0
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.newTag
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.addTag($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "backspace", undefined, $event.key, undefined)) { return null; }
        return _vm.handleBackspace($event);
      }],
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.newTag = $event.target.value;
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }