var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input', {
    ref: "input",
    staticClass: "js-rangeslider",
    attrs: {
      "type": "text",
      "data-min": _vm.min,
      "data-max": _vm.max,
      "data-from": _vm.value,
      "data-prefix": _vm.prefix,
      "data-postfix": _vm.postfix,
      "data-type": _vm.type
    },
    domProps: {
      "value": _vm.value
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }