<template>
  <input ref="input" v-bind="$attrs" type="text" class="js-datepicker" :value="formattedValue" @input="onInputBounce" />
</template>
<script>
import moment from 'moment';
import { debounce } from 'lodash';

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: [Date, String],
      default: ''
    },
    format: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    momentFormat: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    maxViewMode: {
      type: String,
      default: 'centuries'
    },
    minViewMode: {
      type: String,
      default: 'days'
    }
  },
  computed: {
    formattedValue() {
      if (this.value) {
        const incomingFormat = String(this.value).match(/^\d{2}\/\d{2}\/\d{4}$/) ? 'DD/MM/YYYY' : null;

        if (incomingFormat) {
          return moment(this.value, incomingFormat).format(this.momentFormat);
        } else {
          return moment(this.value).format(this.momentFormat);
        }
      }
      return null;
    }
  },
  mounted() {
    jQuery(this.$refs.input).datepicker({
      format: this.format,
      todayHighlight: true,
      autoclose: true,
      weekStart: 1,
      maxViewMode: this.maxViewMode,
      minViewMode: this.minViewMode
    });

    jQuery(this.$refs.input).datepicker('setDate', this.formattedValue);

    jQuery(this.$refs.input).on('changeDate', e => {
      this.$emit('input', moment.utc(e.target.value, this.momentFormat).toDate());
    });
  },
  methods: {
    onInput(e) {
      const date = moment.utc(e.target.value, this.momentFormat);

      if (!date.isValid() || date.year() < 1000) {
        jQuery(this.$refs.input).addClass('is-invalid');
        jQuery(this.$refs.input).datepicker('setDate', null);
        return;
      }

      jQuery(this.$refs.input).removeClass('is-invalid');

      if (e.target.value) return this.$emit('input', date.toDate());
      this.$emit('input', null);
    },
    onInputBounce: debounce(function (e) {
      this.onInput(e);
    }, 1000)
  }
};
</script>
