<template>
  <input
    ref="input"
    v-bind="$attrs"
    class="form-control DateRangePicker"
    :class="{ 'DateRangePicker--time': fullConfig.time }"
    type="text"
    placeholder="Select dates..."
    @input="onInput"
  />
</template>
<script>
import moment from 'moment';
import { reportingPeriods } from '@/lib/helpers';

const defaultConfig = () => ({
  time: false,
  timeFormat: 'HH:mm'
});

export default {
  name: 'DateRangePicker',
  props: {
    value: {
      type: Array,
      default: () => [null, null]
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    maxViewMode: {
      type: String,
      default: 'centuries'
    },
    minViewMode: {
      type: String,
      default: 'days'
    },
    customDateRange: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dateRange: [null, null]
    };
  },
  computed: {
    moment() {
      return this.config?.utc ? moment.utc : moment;
    },
    fullConfig() {
      return {
        ...defaultConfig(),
        ...this.config
      };
    }
  },
  watch: {
    value(val) {
      const [startDate, endDate] = [...val];
      this.dateRange = [this.moment(startDate).toDate(), this.moment(endDate).toDate()];
      jQuery(this.$refs.input).data('daterangepicker').setStartDate(this.moment(startDate));
      jQuery(this.$refs.input).data('daterangepicker').setEndDate(this.moment(endDate));
    }
  },
  beforeMount() {
    window.moment = moment;
  },
  mounted() {
    let predefinedRanges;

    if (!this.customDateRange || Object.keys(this.customDateRange).length === 0) {
      predefinedRanges = reportingPeriods();
    } else {
      predefinedRanges = this.customDateRange;
    }

    if (this.value.length !== 2 || !this.value[0] || !this.value[1] || !moment(this.value[0]).isValid() || !moment(this.value[1]).isValid()) {
      const predefinedRange = Object.keys(predefinedRanges).find(range => {
        const [startDate, endDate] = predefinedRanges[range];

        return this.moment(startDate).isSame(this.value[0], 'day') && this.moment(endDate).isSame(this.value[1], 'day');
      });

      const momentRange = predefinedRanges[predefinedRange || Object.keys(predefinedRanges)[0]];
      const value = momentRange.map(d => d.toDate());

      this.$emit('input', value);
    }

    this.dateRange = this.value;

    jQuery(this.$refs.input).daterangepicker({
      moment: this.moment,
      startDate: this.moment(this.dateRange[0]),
      endDate: this.moment(this.dateRange[1]),
      ranges: predefinedRanges,
      timePicker: this.fullConfig.time,
      locale: {
        format: this.format,
        separator: '  ->  ',
        firstDay: 1
      },
      autoUpdateInput: true,
      alwaysShowCalendars: true,
      timePicker24Hour: true
    });

    // Handle when user clicks Apply
    jQuery(this.$refs.input).on('apply.daterangepicker', (e, picker) => {
      let dateRange = [picker.startDate.toDate(), picker.endDate.toDate()];

      if (this.config?.utc) {
        dateRange = [
          moment.utc(picker.startDate.format('YYYY-MM-DDTHH:mm')).toDate(),
          moment.utc(picker.endDate.format('YYYY-MM-DDTHH:mm')).toDate()
        ];
      }

      this.dateRange = dateRange;
      this.$emit('input', dateRange);
    });
  },
  methods: {
    // Handle when user manually types in the box
    onInput(el) {
      if (!el.target.value.includes(' - ')) return;
      const [startDate, endDate] = el.target.value.split(' - ');

      const format = this.fullConfig.time ? `${this.format} ${this.fullConfig.timeFormat}` : this.format;

      if (!moment(startDate, format).isValid()) return;
      if (!moment(endDate, format).isValid()) return;

      this.dateRange = [this.moment(startDate, format).toDate(), this.moment(endDate, format).toDate()];
      this.$emit('input', [this.moment(startDate, format).toDate(), this.moment(endDate, format).toDate()]);
    }
  }
};
</script>
<style lang="scss" scoped>
.DateRangePicker {
  min-width: 220px;

  &--time {
    min-width: 320px;
  }
}
</style>
