var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input', _vm._b({
    ref: "input",
    staticClass: "form-control DateRangePicker",
    class: {
      'DateRangePicker--time': _vm.fullConfig.time
    },
    attrs: {
      "type": "text",
      "placeholder": "Select dates..."
    },
    on: {
      "input": _vm.onInput
    }
  }, 'input', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }