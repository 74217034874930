var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input', _vm._b({
    ref: "input",
    staticClass: "js-datepicker",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formattedValue
    },
    on: {
      "input": _vm.onInputBounce
    }
  }, 'input', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }