<template>
  <div>
    <p class="font-w600 mb-2">Selected Options ({{ value.length }} items)</p>
    <div class="MultiSelectOptions mb-3">
      <div v-if="!value || value.length === 0" class="alert alert-warning mb-2">No options selected</div>
      <div v-else class="bg-lightest rounded-md py-2 px-3">
        <div class="MultiSelectOptions-dropdown">
          <div v-for="(item, index) in value" :key="index" class="d-flex justify-content-between align-items-center my-2 MultiSelectOptions-item">
            <div class="font-w600">
              {{ options.find(option => option.value === item) ? options.find(option => option.value === item).label : item }}
            </div>
            <button type="button" class="btn btn-sm text-danger" @click="removeFromList(item, index)"><i class="fa fa-fw fa-trash"></i></button>
          </div>
        </div>
      </div>
      <div class="text-right mt-1">
        <a v-if="value.length > 0" href="#" class="text-primary font-w600" @click.prevent="onClickClear"> Unselect All</a>
      </div>
    </div>

    <p class="font-w600 mb-2">Search {{ label }} Options</p>
    <div class="MultiSelectOptions">
      <div class="form-group MultiSelectOptions-form mb-2">
        <div class="input-group">
          <input class="form-control" placeholder="Search..." @input="onSearch" />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fa fa-fw fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <Spinner v-if="loading" class="mb-3">Searching...</Spinner>
      <div v-else-if="hasSearched && !visibleOptions?.length" class="alert alert-warning mb-1">No options available</div>
      <div v-else-if="visibleOptions.length" class="MultiSelectOptions-dropdown-wrapper">
        <ul class="MultiSelectOptions-dropdown">
          <li
            v-for="(item, index) in visibleOptions"
            :key="index"
            class="MultiSelectOptions-dropdown-item d-flex justify-content-between align-items-center font-w600"
            @click.prevent="addToList(item, index)"
          >
            {{ item.label }}
          </li>
        </ul>
        <div class="mt-2 text-right">
          <a v-if="visibleOptions.length !== 0" href="#" class="text-primary font-w600 ml-2 mr-2" @click.prevent="onClickSelectAll">Select All</a>
          <a v-if="visibleOptions.length === 0" href="#" class="text-primary font-w600 ml-2 mr-2" @click.prevent="onClickClear">Unselect All</a>
          <a href="#" class="text-primary font-w600 ml-2" @click.prevent="onClickClear">Clear</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import Spinner from './Spinner';
import { uniq } from 'lodash';

export default {
  name: 'MultiSearchSelectOptions',
  components: {
    Spinner
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    alt: {
      type: Boolean,
      default: false
    },
    action: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      hasSearched: false
    };
  },
  computed: {
    visibleOptions() {
      return this.options.filter(option => !this.value.includes(option.value));
    }
  },
  methods: {
    onSearch: debounce(async function (e) {
      this.hasSearched = true;
      this.loading = true;
      await this.action(e.target.value);
      this.loading = false;
    }, 500),
    addToList(item) {
      const newValue = [...this.value, item.value];
      this.$emit('input', newValue);
      this.$emit('itemAdded', item.value);
    },
    removeFromList(item) {
      const newValue = [...this.value].filter(value => value !== item);

      this.$emit('input', newValue);
      this.$emit('itemRemoved', item);
    },
    onClickSelectAll() {
      const newValue = uniq(this.options.map(o => o.value));
      this.$emit('input', newValue);
    },
    onClickClear() {
      this.$emit('input', []);
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/_scss/custom/variables';

.form-control-alt .MultiSelectOptions-dropdown-wrapper {
  background: #fff;
}

.form-control-alt .MultiSelectOptions .input-group-text {
  background: $primary;
  color: #fff;
}
</style>
