var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "input-group"
  }, [_vm.$attrs.type === 'checkbox' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newItem,
      expression: "newItem"
    }],
    ref: "input",
    staticClass: "form-control",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.newItem) ? _vm._i(_vm.newItem, null) > -1 : _vm.newItem
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.newItem,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.newItem = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.newItem = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.newItem = $$c;
        }
      }
    }
  }, 'input', _vm.$attrs, false)) : _vm.$attrs.type === 'radio' ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newItem,
      expression: "newItem"
    }],
    ref: "input",
    staticClass: "form-control",
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.newItem, null)
    },
    on: {
      "change": function change($event) {
        _vm.newItem = null;
      }
    }
  }, 'input', _vm.$attrs, false)) : _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newItem,
      expression: "newItem"
    }],
    ref: "input",
    staticClass: "form-control",
    attrs: {
      "type": _vm.$attrs.type
    },
    domProps: {
      "value": _vm.newItem
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.newItem = $event.target.value;
      }
    }
  }, 'input', _vm.$attrs, false)), _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.add
    }
  }, [_c('i', {
    staticClass: "fa fa-plus fa-fw"
  })])])]), _c('ul', {
    staticClass: "list-group"
  }, _vm._l(_vm.value, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "d-flex bg-lighter py-1 px-2 justify-content-between align-items-center select-border rounded-sm font-w600 mt-2"
    }, [_c('div', [_vm._v(_vm._s(item))]), _c('a', {
      staticClass: "d-block font-w600 text-muted",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.remove(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-times"
    })])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }