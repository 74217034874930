<template>
  <input
    ref="input"
    type="text"
    class="js-rangeslider"
    :value="value"
    :data-min="min"
    :data-max="max"
    :data-from="value"
    :data-prefix="prefix"
    :data-postfix="postfix"
    :data-type="type"
  />
</template>
<script>
export default {
  name: 'RangeSlider',
  props: {
    value: {
      type: [String, Number, Array],
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    to: {
      type: Number,
      default: null
    },
    prefix: {
      type: String,
      default: ''
    },
    postfix: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'single'
    }
  },
  data() {
    return {
      lastVal: this.type === 'single' ? this.from : [this.from, this.to]
    };
  },
  watch: {
    value(val) {
      if (this.type === 'double' && (val[0] !== this.lastVal[0] || val[1] !== this.lastVal[1])) {
        const slider = jQuery(this.$refs.input).data('ionRangeSlider');
        slider.update({
          from: val[0],
          to: val[1]
        });
      } else if (this.type === 'single' && val !== this.lastVal) {
        const slider = jQuery(this.$refs.input).data('ionRangeSlider');
        slider.update({
          from: val
        });
      }
    }
  },
  mounted() {
    jQuery(this.$refs.input)
      .addClass('js-rangeslider-enabled')
      .ionRangeSlider({
        input_values_separator: ';',
        skin: 'round',
        onChange: data => {
          if (this.type === 'double') {
            this.$emit('input', [data.from, data.to]);
            this.$emit('onSlide', [data.from, data.to]);
            this.lastVal = [data.from, data.to];
          } else {
            this.$emit('input', data.from);
            this.$emit('onSlide', data.from);
            this.lastVal = data.from;
          }
        }
      });
  }
};
</script>
