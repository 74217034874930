<template>
  <input ref="input" v-bind="$attrs" class="form-control" type="text" placeholder="Select date..." @input="onInput" />
</template>
<script>
import moment from 'moment';

export default {
  name: 'DateTimePicker',
  props: {
    value: {
      type: [Date, String],
      default: () => null
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY HH:mm'
    },
    maxViewMode: {
      type: String,
      default: 'centuries'
    },
    minViewMode: {
      type: String,
      default: 'days'
    },
    customDateRange: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    moment() {
      return this.config?.utc ? moment.utc : moment;
    }
  },
  watch: {
    value(val) {
      jQuery(this.$refs.input).data('daterangepicker').setStartDate(this.moment(val));
    }
  },
  beforeMount() {
    window.moment = moment;
  },
  mounted() {
    jQuery(this.$refs.input).daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      moment: this.moment,
      startDate: this.moment(this.value),
      locale: {
        format: this.format,
        firstDay: 1
      },
      autoUpdateInput: true,
      timePicker24Hour: true
    });

    // Handle when user clicks Apply
    jQuery(this.$refs.input).on('apply.daterangepicker', (e, picker) => {
      let date = picker.startDate.toDate();

      console.log(picker.startDate);

      if (this.config?.utc) {
        date = moment.utc(picker.startDate.format('YYYY-MM-DDTHH:mm')).toDate();
      }

      this.$emit('input', date);
    });
  },
  methods: {
    // Handle when user manually types in the box
    onInput(el) {
      if (!el.target.value) return;
      const startDate = el.target.value;

      if (!moment(startDate, this.format).isValid()) return;

      if (this.config?.utc) {
        this.$emit('input', moment.utc(startDate, this.format).toDate());
      } else {
        this.$emit('input', moment(startDate, this.format).toDate());
      }
    }
  }
};
</script>
