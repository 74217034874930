var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "MultiSelectOptions"
  }, [_vm.search ? _c('div', {
    staticClass: "form-group MultiSelectOptions-form mb-2"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Search..."
    },
    on: {
      "input": _vm.onSearch
    }
  }), _vm._m(0)])]) : _vm._e(), !_vm.availableOptions || _vm.availableOptions.length === 0 ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No options available")]) : _c('div', {
    staticClass: "MultiSelectOptions-dropdown-wrapper"
  }, [_c('ul', {
    staticClass: "MultiSelectOptions-dropdown"
  }, _vm._l(_vm.availableOptions, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "MultiSelectOptions-dropdown-item d-flex justify-content-between align-items-center font-w600",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickCheckbox(item);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "DataTableColumns-checkbox custom-control custom-checkbox mr-3"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": "input-".concat(item.value),
        "type": "checkbox",
        "name": "checkbox-".concat(item.value)
      },
      domProps: {
        "checked": item.checked
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": "checkbox-".concat(item.value)
      }
    })]), _c('div', [_vm._v(_vm._s(item.label))])])]);
  }), 0), _c('div', {
    staticClass: "mt-3 d-flex align-items-end justify-content-end"
  }, [_c('div', {
    staticClass: "text-muted font-w600 font-size-sm"
  }, [_vm._v(_vm._s(_vm.value.length) + " items selected")]), _c('div', {
    staticClass: "ml-3"
  }, [!_vm.availableOptions.every(function (a) {
    return a.checked;
  }) ? _c('a', {
    staticClass: "text-primary font-w600 ml-2 mr-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickSelectAll($event);
      }
    }
  }, [_vm._v("Select All")]) : _vm._e(), _vm.availableOptions.every(function (a) {
    return a.checked;
  }) ? _c('a', {
    staticClass: "text-primary font-w600 ml-2 mr-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickClear($event);
      }
    }
  }, [_vm._v("Unselect All")]) : _vm._e(), _c('a', {
    staticClass: "text-primary font-w600 ml-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickClear($event);
      }
    }
  }, [_vm._v("Clear")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-append"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-search"
  })])]);
}]

export { render, staticRenderFns }